<template>
  <v-dialog v-model="show_dialog" width="500px">
    <v-card>
      <!-- <DivLoader v-if="dialog.loading" :loading_type="'article, list-item-two-line, list-item-two-line, actions'"> -->

      <!-- </DivLoader> -->
      <!-- <div v-else> -->
      <div>
        <v-form ref="followup_date_form" @submit.prevent="addComment()">
          <v-card-title class="pl-7">
            <span class="headline">{{ $lang.ADD_COMMENT }}</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="followup_comment_form">
              <v-container>
                <v-row>
                  <v-col class="py-1" cols="12" md="12" sm="12">
                    <v-menu
                      v-model="date_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="290px"
                      offset-y
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="display_date"
                          v-on="on"
                          :label="$lang.FOLLOW_UP_DATE"
                          :placeholder="$lang.FOLLOW_UP_DATE"
                          class="filter"
                          dense
                          readonly
                        >
                          <template slot="append">
                            <!--                    <v-icon v-if="dialog.follow_up_date" @click="clearDateFilter()">mdi-close</v-icon>-->
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="follow_up_date" :min="today">
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="date_menu = false"
                          >Cancel</v-btn
                        >
                        <v-btn color="primary" text @click="formateDisplayDate"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col class="py-1" cols="12" md="12" sm="12">
                    <v-text-field
                      v-on:keyup.enter="addComment()"
                      v-model="comment"
                      :label="$lang.COMMENT"
                      maxlength="100"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions class="pb-5 px-5">
            <v-spacer></v-spacer>
            <v-btn
              class="px-5  py-4 "
              color="secondary_2"
              outlined
              rounded
              @click="show_dialog = false"
            >
              <b> {{ $lang.CANCEL }}</b>
            </v-btn>
            <v-btn
              class="px-7  py-5 "
              :loading="save_btn_loader"
              color="secondary_2"
              rounded
              type="submit"
              @click="addComment"
            >
              <b style="color: #ffff"> {{ $lang.SAVE }}</b>
            </v-btn>
          </v-card-actions>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  //   props: ['dialog'],
  components: {
    // DivLoader: () => import("@/components/ui/DivLoader"),
  },
  data: () => ({
    display_date: null,
    date_menu: false,
    today: new Date().toISOString().substr(0, 10),
    save_btn_loader: false,

    show_dialog: false,
    customer_id: null,
    follow_up_date: null,
    comment: "",
  }),
  created() {
    // this.initialize()
  },
  methods: {
    async openFollowupDialog(item) {
      this.show_dialog = true;
      this.display_date = this.display_date_formate(item.follow_up_date);
      this.follow_up_date = item.follow_up_date;
      this.customer_id = item.customer_id;
      this.comment = item.remark;
      console.log("openFollowupDialog", item);
    },
    /* reset the form */
    resetForm() {
      this.$refs.add_member_form.reset();
    },
    /* function for save comments */
    addComment() {
      if (this.$refs.followup_comment_form.validate()) {
        const self = this;
        self.save_btn_loader = true;
        self.login_loading = true;
        var form = new FormData();
        if (self.display_date) {
          form.append("follow_up_date", self.display_date);
        }
        if (self.comment) {
          form.append("remark", self.comment);
        }

        const successHandler = () => {
          self.showSnakeBar("success", "Successfully updated");
          self.show_dialog = false;
          self.$emit("update");
        };
        const finallyHandler = () => {
          self.save_btn_loader = false;
        };
        this.request_POST(
          self,
          this.$urls.ADD_FOLLOW_UP_DATE + `${this.customer_id}/`,
          form,
          successHandler,
          null,
          null,
          finallyHandler
        );
      }
    },
    /* function to format the date */
    formateDisplayDate() {
      this.display_date = this.display_date_formate(this.follow_up_date);
      this.date_menu = false;
    },
  },
};
</script>
